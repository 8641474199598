
const sidebarMenuList = ['DASHBOARD_MENU', 'FACT_MENU', 'USER_MENU', 'ROLE_MENU', 'CATEGORY_MENU', 'QUIZ_MENU'];
const userTableActionList = ['ASSIGN_ROLE_USER'];
const factTableActionList = ['EDIT_FACT', 'VIEW_LOG_FACT', 'CHANGE_STATUS_FACT', 'EDIT_FACT_JSON'];

const roleOrder = ['ADMIN', 'REVIEWER', 'CONTRIBUTOR'];

const rolePermissions = {
    ADMIN: [...sidebarMenuList, ...userTableActionList, ...factTableActionList],
    CONTRIBUTOR: [sidebarMenuList[1], factTableActionList[0], factTableActionList[1], factTableActionList[3]],
    REVIEWER: [sidebarMenuList[1], factTableActionList[0], factTableActionList[1], factTableActionList[2], factTableActionList[3]]
}

export function isPermissionGranted(roles, permission) {
    const validRoles = roles?.filter((role) => roleOrder.includes(role));

    if (!validRoles || validRoles.length === 0) {
        return false;
    }

    const highestRole = validRoles.sort(
        (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
    )[0];

    return rolePermissions[highestRole]?.includes(permission) || false;
}

export function getHighestRole(roles) {
    const validRoles = roles?.filter((role) => roleOrder.includes(role));

    if (!validRoles || validRoles.length === 0) {
        return false;
    }

    const highestRole = validRoles.sort(
        (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
    )[0];

    return highestRole;
}

export function canAccessApp(roles) {
    const validRoles = roles?.filter((role) => roleOrder.includes(role));

    if (!validRoles || validRoles.length === 0) {
        return false;
    }
    return true;
}

export function initialRoute(roles) {
    const validRoles = roles?.filter((role) => roleOrder.includes(role));

    if (!validRoles || validRoles.length === 0) {
        return '/dashboard';
    }

    const highestRole = validRoles.sort(
        (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
    )[0];

    if (highestRole === 'ADMIN') {
        return '/dashboard'
    }
    else if (highestRole === 'REVIEWER' || highestRole === 'CONTRIBUTOR') {
        return '/facts'
    }
}